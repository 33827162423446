<template>
	<div>
		<Header></Header>
		
		<div class="content-area" >
			<div class="standard-page-header">
				National Championships
			</div>
            <div class="frontend-content-wrapper">
				<div class="section-spacing">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/2024/2024 National Championship Logo.png" width="100%" alt="National Championship Logo">
                        </div>
                        <div class="col-md-8">
                            <div id="map"></div>
                        </div>
                    </div>
                    <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/2024/National Landing page banner.png" width="100%" alt="National Landing Banner">
                    <div style="margin-top: 20px;">

                        <Accordion>
                            <template v-slot:header>
                                2024 National Championships
                            </template>
                            <template v-slot:content>
                                <span v-if="nationals_main">
                                    <span v-if="nationals_main.content">
                                        <PageContentBox :content="parseMainContent(nationals_main)"></PageContentBox>
                                    </span>
                                </span>
                            </template>
                        </Accordion>

                        <Accordion  v-for="national in nationals_hosts" :key="national.id">
                            <template v-slot:header>
                                {{ national.name }}
                            </template>
                            <template v-slot:content>
                                <span v-if="national.content">
                                    <PageContentBox :content="parseMainContent(national)"></PageContentBox>
                                </span>
                            </template>
                        </Accordion>
                        <Accordion>
                            <template v-slot:header>
                                Previous Tournament Results
                            </template>
                            <template v-slot:content>
                                <div class="panel">
                                    <div role="button" class="panel-heading" v-on:click="first_decade = !first_decade">2020+<i :class="['fas', { 'fa-plus': first_decade === false }, { 'fa-minus': first_decade === true }]" style="float: right;"></i></div>
                                    <div class="panel-content" v-if="first_decade">
                                        <b-tabs>
                                            <b-tab-item label="2024">
                                                <PageContentBox :content="grabTournamentResults('2024')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2023">
                                                <PageContentBox :content="grabTournamentResults('2023')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2022">
                                                <PageContentBox :content="grabTournamentResults('2022')"></PageContentBox>
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                </div>
                                <div class="panel">
                                    <div role="button" class="panel-heading" v-on:click="twenty_decade = !twenty_decade">2019-2010<i :class="['fas', { 'fa-plus': twenty_decade === false }, { 'fa-minus': twenty_decade === true }]" style="float: right;"></i></div>
                                    <div class="panel-content" v-if="twenty_decade">
                                        <b-tabs>
                                            <b-tab-item label="2019">
                                                <PageContentBox :content="grabTournamentResults('2019')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2018">
                                                <PageContentBox :content="grabTournamentResults('2018')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2017">
                                                <PageContentBox :content="grabTournamentResults('2017')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2016">
                                                <PageContentBox :content="grabTournamentResults('2016')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2015">
                                                <PageContentBox :content="grabTournamentResults('2015')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2014">
                                                <PageContentBox :content="grabTournamentResults('2014')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2013">
                                                <PageContentBox :content="grabTournamentResults('2013')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2012">
                                                <PageContentBox :content="grabTournamentResults('2012')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2011">
                                                <PageContentBox :content="grabTournamentResults('2011')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2010">
                                                <PageContentBox :content="grabTournamentResults('2010')"></PageContentBox>
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                </div>
                                <div class="panel">
                                    <div role="button" class="panel-heading" v-on:click="second_decade = !second_decade">2009-2000<i :class="['fas', { 'fa-plus': second_decade === false }, { 'fa-minus': second_decade === true }]" style="float: right;"></i></div>
                                    <div class="panel-content" v-if="second_decade">
                                        <b-tabs>
                                            <b-tab-item label="2009">
                                                <PageContentBox :content="grabTournamentResults('2009')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2008">
                                                <PageContentBox :content="grabTournamentResults('2008')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2007">
                                                <PageContentBox :content="grabTournamentResults('2007')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2006">
                                                <PageContentBox :content="grabTournamentResults('2006')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2005">
                                                <PageContentBox :content="grabTournamentResults('2005')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2004">
                                                <PageContentBox :content="grabTournamentResults('2004')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2003">
                                                <PageContentBox :content="grabTournamentResults('2003')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2002">
                                                <PageContentBox :content="grabTournamentResults('2002')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2001">
                                                <PageContentBox :content="grabTournamentResults('2001')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="2000">
                                                <PageContentBox :content="grabTournamentResults('2000')"></PageContentBox>
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                </div>
                                <div class="panel">
                                    <div role="button" class="panel-heading" v-on:click="third_decade = !third_decade">1999-1996<i :class="['fas', { 'fa-plus': third_decade === false }, { 'fa-minus': third_decade === true }]" style="float: right;"></i></div>
                                    <div class="panel-content" v-if="third_decade">
                                        <b-tabs>
                                            <b-tab-item label="1999">
                                                <PageContentBox :content="grabTournamentResults('1999')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="1998">
                                                <PageContentBox :content="grabTournamentResults('1998')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="1997">
                                                <PageContentBox :content="grabTournamentResults('1997')"></PageContentBox>
                                            </b-tab-item>
                                            <b-tab-item label="1996">
                                                <PageContentBox :content="grabTournamentResults('1996')"></PageContentBox>
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                </div>
                            </template>
                        </Accordion>
                    </div>
				</div>
			</div>
        </div>

		<Footer></Footer>
	</div>
</template>

<script>
import axios from 'axios'
import Header from './components/PrimaryHeader.vue'
import Footer from './components/PrimaryFooter.vue'
import Form from '../components/Form.vue'
import PageContentBox from '../frontend/PageContentBox.vue'
export default {
    metaInfo() {
        return { 
            title: "National Championships - Slo-Pitch National",
            meta: [
                { name: 'description', content:  'During the COVID-19 situation, Slo-Pitch National Regional Directors announced the postponement of the 2020 Slo-Pitch National, National Championships until July 29 – August 2, 2021. The Divisions and Locations that were slated for 2020 will be hosted in the same locations in 2021. The Divisions and Locations that were announced for 2021 will be moved to 2022 and in the fall, we will announce the 2023 allocations. We look forward to rebounding from the frustrations of the 2020 season and celebrating this championship again in 2021.' },
                { property: 'og:title', content: "National Championships - Slo-Pitch National"},
                { property: 'og:url', content:  'https://www.slo-pitch.com/national-championships' },
                { property: 'og:image', content: 'https://slo-pitch.com/uploads/facebook/nationalchampionships.png' },
                { property: 'og:site_name', content: 'Slo-Pitch National'},
                { property: 'og:type', content: 'website' },    
                { name: 'robots', content: 'index,follow' } 
            ]
        }
    },
	data() {
		return {
			en: true,
            page_url: null,
            page: null,
            previous_results: null,
            twenty_decade: false,
            first_decade: false,
            second_decade: false,
            third_decade: false,
            nationals_main: 'Test',
            nationals_hosts: [],
            brampton: false,
            winnipeg: false,
            edmonton: false,
            leduc: false,
            kelowna: false,
            activeTab: 'default'
		};
	},
	components: {
		Header,
		Footer,
        Form,
        PageContentBox
	},
	mounted: function() {

        if(this.$route.query.page) {
            this.activeTab = this.$route.query.page
        }

		if(sessionStorage.getItem('lang')) {
            
            if(sessionStorage.getItem('lang') === 'true') {
                this.en = true
            }
            if(sessionStorage.getItem('lang') === 'false') {
                this.en = true
            }
        }
        var vm = this

        ////// FULL MAP
        const center = { lat: 52.1167, lng: -97.2500 }
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 3,
            center: center,
        });
        /////// FULL MAP END

        ////// WHITEHORSE - now winnipeg
        const whitehorse = { lat: 52.1579, lng: -106.6702 }
        
        // The marker, positioned at Uluru
         const whitehorseContentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Saskatoon</h1>' +
            '<div id="bodyContent">' +
            "<p>Mens C, 35D </p>" +
            "<p>Womens C, E, 35 Comp, 35 IM </p>" +
            "</div>" +
            "</div>";
        const whitehorseInfoWindow = new google.maps.InfoWindow({
            content: whitehorseContentString,
        });
        const whitehorseMarker = new google.maps.Marker({
            position: whitehorse,
            map: map,
        });
        whitehorseMarker.addListener("click", () => {
            whitehorseInfoWindow.open(map, whitehorseMarker);
        });
        ////// WHITEHORSE END


        /////// LEDUC
        const leduc = { lat: 47.5556, lng: -52.7453 }
        const leducMarker = new google.maps.Marker({
            position: leduc,
            map: map
        });

        const leducContentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<div id="bodyContent">' +
            "<h4 style='margin-top: 0px;'>St. John's, NL </h4>" +
            "<p>Mens D, 35E 50E 60</p>" +
            "<p>Women's D</p>" +
            "</div>" +
            "</div>";
        const leducInfoWindow = new google.maps.InfoWindow({
            content: leducContentString,
        });

        leducMarker.addListener("click", () => {
            leducInfoWindow.open(map, leducMarker);
        });
        ///////// LEDUC END


        ///////// ST JOHNS - is now Moncton
        const stjohns = { lat: 49.8863, lng: -119.4966 }
        const stJohnsMarker = new google.maps.Marker({
            position: stjohns,
            map: map,
        }); 

        const stJohnsContentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<div id="bodyContent">' +
                '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Kelowna</h1>' +
            "<p>Coed SS Comp, C, D, E</p>" +
            
            "</div>" +
            "</div>";
        const stJohnsInfoWindow = new google.maps.InfoWindow({
            content: stJohnsContentString,
        });
        stJohnsMarker.addListener("click", () => {
            stJohnsInfoWindow.open(map, stJohnsMarker);
        });
        ////// ST JOHNS END
        
        //////// WINNIPEG
        // const winnipeg = { lat: 49.8538008, lng: -97.2224356 }
        // const marker4 = new google.maps.Marker({
        //     position: winnipeg,
        //     map: map,
        // });

        // marker4.addListener("click", () => {
        //     infowindow3.open(map, marker4);
        // });
        
        // is now orillia
        const brampton = { lat: 44.3894, lng:-79.6903 }
        const contentString4 =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h1 id="firstHeading" style="font-size: 20px; margin-top: 0px;" class="firstHeading">Barrie</h1>' +
            '<div id="bodyContent">' +
            "<p>Mens E, 35 Comp, 35C, 50 Comp, 50IM</p>" +
            "<p>Womens B</p>" +
            "<p>Coed Comp, C, D, E</p>" +
            

            "</div>" +
            "</div>";
        const infowindow4 = new google.maps.InfoWindow({
            content: contentString4,
        });
        ////// WINNIPEG END

        const marker5 = new google.maps.Marker({
            position: brampton,
            map: map,
        });

        marker5.addListener("click", () => {
            infowindow4.open(map, marker5);
        });

        axios.get('/api/pages/national-results').then(results => {
            if(results.data.success === true) {
                vm.nationals_main = results.data.nationals_main
                vm.previous_results = results.data.nationals_results
                vm.nationals_hosts = results.data.national_hosts
            }
        })
     
	},
	componentWillReceiveProps(nextProps){

	},
	computed: {
	
	},
	methods: {
        
        grabNationalsHost: function(city) {
            var vm = this
            var tourney = _.find(vm.nationals_hosts, function(result) {
                return result.slug === 'nationals-host-' + city
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)

                return content
            }
            
            // var content = JSON.parse(tourney)
            return []
            // return tourney
        },
        grabTournamentResults: function(year) {
            var vm = this
            var tourney = _.find(vm.previous_results, function(result) {
                return result.slug === 'nc-' + year
            })

            if(tourney) {
                var content = JSON.parse(tourney.content)
                return content
            }

            return []
        },
		openPanel:function(panel) {
			if(panel.switched === "false") {
				panel.switched = "true"
			} else {
				panel.switched = "false"
			}
		},
        parseMainContent: function(content) {
            return JSON.parse(content.content)
        },
		createProperTableFunction(table) {
			// first row is your columns
			var first_row = table[0]
			var all_rows = table
			var columns = []
			var data = []
	
			var i = 0
			for(i; i < all_rows.length; i++) {
				var row = all_rows[i]
				if(i === 0) {
					// this is the first row, need to for loop again though
					var s = 0

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						columns.push({
							field: column.name,
							label: column.name
						})
					}
				} else {
					// this is the rest of the rows, need to loop and then call via the loop to the first row
					var s = 0
					
					var object = {}

					for(s; s < row.row.length; s++) {
						var column = row.row[s]
						
						var col_name = all_rows[0].row[s].name
						
						object[col_name] = column.name
					}
					data.push(object)
				}
			}

			// every row after is your data
			


			return {
				columns: columns,
				data: data,
				table: table
			}
		},
	}
}
</script>

<style lang="scss">
    @import '../../styles/Frontend.scss';
</style>
