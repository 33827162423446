<template>
<span>
    <div class="advertising-bar">

    </div>
    <div class="sponsor-bar">
        <div class="sponsor-row">
            <div class="sponsor">
                <a role="button" v-on:click="selectSponsor('https://rawlings.com/')">
                    <img src="../../../images/footer/sponsors/rawlings.png" alt="Rawlings Sponsor Ad">
                </a>
            </div>
            <div class="sponsor">
                <a role="button" v-on:click="selectSponsor('https://worth.rawlings.com/')">
                    <img src="../../../images/footer/sponsors/worth.png" alt="Worth Sponsor Ad">
                </a>
            </div>
            <div class="sponsor">
                <a role="button" v-on:click="selectSponsor('https://www.perkopolis.com/')">
                    <img src="https://cdn.blacktiecollab.com/slo-pitch.com/images/perkopolis3.png" alt="Perkopolis Sponsor Ad">
                </a>
            </div>
            
        </div>
        <div class="sponsor-row">
            <div class="sponsor">
                <a role="button" v-on:click="selectSponsor('https://www.easton.com/')">
                    <img style="margin-top: 40px;" src="../../../images/footer/sponsors/easton.png" alt="Easton Sponsor">
                </a>
            </div>
            <div class="sponsor">
                <a role="button" v-on:click="selectSponsor('https://homerunsports.com/discount/SPN4YOU')">
                    <img src="../../../images/footer/sponsors/miken-1.png" alt="SPN4YOU">
                </a>
            </div>
            <div class="sponsor">
                <a v-on:click="selectSponsor('https://homerunsports.com/discount/SPN4YOU')">
                    <img src="../../../images/footer/sponsors/HomeRunSports-Logo-Black-Red.png" alt="Home Run Sports Logo">
                </a>
            </div>
        </div>
    </div>
    <div class="ads">
        <div class="ads-row">
            <div class="ad">
                <a role="button" v-on:click="selectSponsor(adRandomizerBottom[0].url)" target="_blank">
                    <img :src="adRandomizerBottom[0].image_url" alt="Random Ad from Sponsor">
                </a>
            </div>
            <div class="ad">
                <a role="button" v-on:click="selectSponsor(adRandomizerBottom[1].url)" target="_blank">
                    <img :src="adRandomizerBottom[1].image_url" alt="Random Ad from Sponsor">
                </a>
            </div>
            <div class="ad">
                <a role="button" v-on:click="selectSponsor(adRandomizerBottom[2].url)" target="_blank">
                    <img :src="adRandomizerBottom[2].image_url" alt="Random Ad from Sponsor">
                </a>
            </div>
        </div>
    </div>
    <div class="footer" role="contentinfo" aria-label="Footer content">
        <div class="footer-wrapper">
            <div class="row">  
                <div class="col-md-3">
                    <h3>{{ en === true ? 'Additional Links' : 'Liens supplémentaires' }}</h3>
                    <ul>
                        <li v-for="link in additional_links" :key="link.id">
                            <a role="button" :href="link.href">{{ link.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>{{ en === true ? 'Customer Service' : 'Service Clients' }}</h3>
                    <ul>
                        <li v-for="link in customer_service" :key="link.id">
                            <a role="button" :href="link.href">{{ link.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h3>{{ en === true ? 'Connect With Us' : 'Connecte-toi avec nous' }}</h3>
                    <div style="text-align: center;">
                        <a role="button" href="https://www.facebook.com/SloPitchNational" target="_blank" class="social-button">
                            <i class="fab fa-facebook-f" role="presentation"></i>
                        </a>
                        <a role="button" href="https://twitter.com/spnslopitch" target="_blank" class="social-button">
                            <i class="fab fa-twitter" role="presentation"></i>
                        </a>
                        <a role="button" href="https://www.pinterest.com/spnslopitch/" target="_blank" class="social-button">
                            <i class="fab fa-pinterest-p" role="presentation"></i>
                        </a>
                        <a role="button" href="https://www.instagram.com/spnslopitch/" target="_blank" class="social-button">
                            <i class="fab fa-instagram" role="presentation"></i>
                        </a>
                        <a role="button" href="https://www.youtube.com/channel/UCAH0gIUTWZSObWcY8EZ0xBA" target="_blank" class="social-button">
                            <i class="fab fa-youtube" role="presentation"></i>
                        </a>                        
                    </div>
                </div>
                <div class="col-md-3">
                    <h3>{{ en === true ? 'Your Account' : 'Votre compte' }}</h3>
                    <ul>
                        <li v-for="link in your_account" :key="link.id">
                            <a role="button" :href="link.href">{{ link.name }}</a>
                        </li>
           
                    </ul>
                </div>

            </div>
            <div class="footer-line"></div>
            <div class="footer-info">
                <div class="row">
                    <div class="col-md-2">
                        <a role="button">
                            <img src="../../../images/header/SPNLogo2019-White.png" style="max-width: 100px; margin: 20px;" alt="SPN Logo">
                        </a>
                    </div>
                    <div class="col-md-8">
                        <div class="footer-main-text">
                            {{ en === true ? `© ${moment().format('YYYY')} Slo-Pitch National All rights reserved.` : `© ${moment().format('YYYY')} Slo-Pitch National Tous droits réservés.` }}
                        </div>
                    </div>
                    <div class="col-md-2">
                        <a role="button" href="https://blacktiecollab.com/" target="_blank" class="footer-blacktie">
                            <img src="https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/Logo (White).png" alt="SPN Logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
</span>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
var the_window = window

export default {
    data() {
        return {
            user_coordinates: {
                lat: null,
                long: null
            },
            
            adRandomizerBottom: [
				{ url: 'https://easton.com/', image_url: '/uploads/ads/easton.png' },
				{ url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/1-1.jpeg' },
				{ url: '/umpires/take-the-exam', image_url: '/uploads/ads/makethecall.png' },
                { url: 'https://miken.rawlings.com/', image_url: '/uploads/ads/1-4.png' },
                { url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/1-1.png' },
                { url: 'https://rawlingssports.ca/collections/black-clover-collection', image_url: '/uploads/ads/1-2.png'},
                { url: 'https://gameonmobile.com', image_url: '/uploads/ads/thumbnail_spn1.png'},

                { url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/9worthbats.png'},
                { url: 'https://miken.rawlings.com/', image_url: '/uploads/ads/9mikenbats.png'},
                { url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/9biggerbetter.png'},
                { url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/9pitchershelmet.png'},
                { url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/9customizer.png'},
                { url: 'https://homerunsports.com/discount/SPN4YOU', image_url: '/uploads/ads/9worthmat.png'},
                { url: 'https://www.rawlings.com', image_url: '/uploads/ads/9buildingchamps.png'},
                { url: 'https://rawlingssports.ca/collections/rawlings-leather-goods-collection/products/rawlings-rugged-leather-backpack', image_url: '/uploads/ads/9leathergoods.png'},
			],
            submenu_filtered: [],
            additional_links: [],
            customer_service: [],
            your_account: [],
            submenus: [],
            the_window: the_window,
            authUser: null,
            en: true
        };
    },
    components: {
        
    },
    computed: {
        
    },
    mounted: function() {
        var vm = this

        axios.get('/auth/check').then(results => {  
            if(results.data.type === "success") {
                vm.authUser = results.data.user
            }
        })

        vm.adRandomizerBottom = _.shuffle(vm.adRandomizerBottom)
        

        axios.get('/api/navigation/additional-links').then(results => {
            if(results.data) {
                vm.additional_links = results.data.main_menu
            }
        }).catch(err => {
            console.log(err)
        })
        axios.get('/api/navigation/customer-service').then(results => {
            if(results.data) {
                vm.customer_service = results.data.main_menu
            }
        }).catch(err => {
            console.log(err)
        })
        axios.get('/api/navigation/my-account').then(results => {
            if(results.data) {
                vm.your_account = results.data.main_menu
            }
        }).catch(err => {
            console.log(err)
        })
    },
    methods: {
        moment: function(date, format = null) {
            return moment(date, format)
        },
        selectMenu(item) {
            var vm = this
            
            if(item.has_submenu) {
                var filter = _.filter(vm.submenus, function(sub_item) {
                    return sub_item.submenu_id === item.slug
                })

                vm.submenu_filtered = filter
            }
            if(item.href) {
                // redirect
                router.push({ path: '/content/' + item.href })
            }
        },
        selectSubMenu(item) {
            var vm = this
        
            this.$router.push({ path: '/content/' + item.href }).catch(err => {})   
        },
        selectSponsor(sponsor) {
            var user_id = null
            var vm = this
            
            if(vm.authUser) {
                user_id = vm.authUser.id
            }

            axios.post('/api/pages/track-link-clicks', {
                user_id: user_id,
                sponsor: sponsor
            }).then(results => {
                this.the_window.location.href = sponsor
            })

            
            // track the click and then redirect them
        }
    }
}
</script>

<style lang="scss">
    @import '../../../styles/Footer.scss';
</style>
